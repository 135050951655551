export const translations = {
    PL: {
        pageTitle: 'Konto',
        linkAccount: 'Konto',
        loading: 'Logowanie...',
        loginHeader: 'Logowanie',
        registerHeader: 'Rejestracja',
        editHeader: 'Edycja danych',
        activateHeader: 'Aktywowanie konta',
        loginLabel: 'Nazwa użytkownika',
        emailLabel: 'Adres e-mail',
        emailLabelRep: 'Powtórz adres e-mail',
        passwdLabel: 'Hasło',
        passwdLabelRep: 'Powtórz hasło',
        loginSubmitText: 'Zaloguj',
        registerSubmitText: 'Załóż konto',
        logoutSubmitText: 'Wyloguj',
        registerSuccess: 'Konto stworzone. Sprawdź e-mail.',
        sync: 'Synchronizuj',
        syncAvailable: 'Synchronizacja dostępna',
        errorFetch: 'Błąd logowania - spróbuj ponownie',
        errorUserId: 'Podano błędny login',
        errorPassword: 'Podano błędne hasło',
        errorEmail: 'Podano błędny e-mail',
        errorActivationUserNotFound: 'Wskazany użytkownik nie istnieje.',
        errorActivationToken: 'Token jest zbyt stary. Wygeneruj nowy token.',
    },
    ENG: {
        pageTitle: 'Account',
        linkAccount: 'Account',
        loading: 'Loading...',
        loginHeader: 'Login',
        registerHeader: 'Register',
        editHeader: 'Edit account details',
        activateHeader: 'Activating account',
        loginLabel: 'Login',
        emailLabel: 'E-mail',
        emailLabelRep: 'Repeat e-mail',
        passwdLabel: 'Password',
        passwdLabelRep: 'Repeat password',
        loginSubmitText: 'Submit',
        registerSubmitText: 'Register',
        logoutSubmitText: 'Logout',
        registerSuccess: 'Account created. Check e-mail.',
        sync: 'Synchronize',
        syncAvailable: 'Sync option available',
        errorFetch: 'Error sending request - try again',
        errorUserId: 'Wrong login provided',
        errorPassword: 'Wrong password provided',
        errorEmail: 'Wrong e-mail provided',
        errorActivationUserNotFound: 'Selected user not found.',
        errorActivationToken: 'Token expired. Generate new activation token',
    },
};
