import React, {useState} from 'react';
import {useDataDispatch} from 'modules/DataState/DataState.jsx';
import scssVariables from '../../styles/imports/variables.scss';
import {HuePicker} from 'react-color';
import {useAppState} from 'modules/AppState/AppState.jsx';
import {translations} from './translations';
import {GoPlusSmall} from 'react-icons/go';
import {string, number} from 'prop-types';

export const SettingsGroupRecordNew = (props) => {
    const {lang} = useAppState();
    const dataDispatch = useDataDispatch();
    const defaultNewRecord = {n: '', a: '', d: '', c: scssVariables.brandColor1, b: []};
    const [newRecord, setNewRecord] = useState(Object.assign({}, defaultNewRecord));

    const setNewRecordValues = (key, value) => {
        let tempNewRecord = Object.assign({}, newRecord);

        if (key === 'a') {
            if (/^[0-9]{0,26}$/.test(value)) {
                tempNewRecord[key] = value;
            }
        } else {
            tempNewRecord[key] = value;
        }

        setNewRecord(tempNewRecord);
    };

    const addNewRecord = () => {
        if (newRecord.n !== '') {
            dataDispatch({type: 'addGroupRecord', data: {data: newRecord, groupName: props.groupName, groupIndex: props.groupIndex}});
            setNewRecord(Object.assign({}, defaultNewRecord));
        }
    };

    return (
        <li className="grid__item settings__group-item--add">
            <div className="grid__item-inner" style={{borderColor: newRecord.c, backgroundColor: `${newRecord.c}11`}}>
                <div className="settings__group-item-form">
                    <div className="settings__group-item-fieldset">
                        <label htmlFor={`rec_an_${props.groupIndex}`} className="settings__group-item-label">
                            {translations[lang].recordName}
                        </label>
                        <input
                            id={`rec_an_${props.groupIndex}`}
                            type="text"
                            value={newRecord.n}
                            className="settings__group-item-input"
                            onChange={(e) => {
                                setNewRecordValues('n', e.target.value);
                            }}
                        />
                    </div>
                    <div className="settings__group-item-fieldset">
                        <label htmlFor={`rec_aa_${props.groupIndex}`} className="settings__group-item-label">
                            {translations[lang].recordAccount}
                        </label>
                        <input
                            id={`rec_aa_${props.groupIndex}`}
                            type="text"
                            value={newRecord.a}
                            className="settings__group-item-input"
                            onChange={(e) => {
                                setNewRecordValues('a', e.target.value);
                            }}
                        />
                    </div>
                    <div className="settings__group-item-fieldset">
                        <HuePicker
                            color={newRecord.c}
                            onChangeComplete={(color) => {
                                setNewRecordValues('c', color.hex);
                            }}
                        />
                    </div>
                    <div className="settings__group-item-fieldset">
                        <label htmlFor={`rec_ad_${props.groupIndex}`} className="settings__group-item-label">
                            {translations[lang].recordDescription}
                        </label>
                        <textarea
                            id={`rec_ad_${props.groupIndex}`}
                            type="text"
                            value={newRecord.d}
                            className="settings__group-item-textarea"
                            onChange={(e) => {
                                setNewRecordValues('d', e.target.value);
                            }}
                        ></textarea>
                    </div>
                </div>
                <div className="settings__group-item-options">
                    <button
                        className="settings__group-item-add btn"
                        onClick={() => {
                            addNewRecord();
                        }}
                    >
                        <GoPlusSmall className="settings__group-item-add-icon" /> {translations[lang].addText} <strong>{/*rec.n*/}</strong>
                    </button>
                </div>
            </div>
        </li>
    );
};

SettingsGroupRecordNew.propTypes = {
    groupName: string,
    groupIndex: number,
};
