/**
 * Add new message and return new messages array
 * @param {Array} messages prev messages array
 * @param {string} newMessage new message
 * @returns {Array} new messages array
 */
export const addNewMsg = (messages, newMessage) => {
    const messagesLimit = 20;
    const newMessages = [...messages];

    // Remove one item if limit reached
    if (newMessages.length >= messagesLimit) {
        newMessages.shift();
    }

    // Add new message
    newMessages.push({time: new Date().getTime(), text: newMessage});

    // Return new messages
    return newMessages;
};

/**
 * Get readable date from timestamp
 * @param {number} timestamp date timestamp
 * @returns {string} date string
 */
export const readableDateFromTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const prefixWithZero = (val) => {
        return val < 10 ? `0${val}` : val;
    };

    return `${prefixWithZero(date.getDate())}-${prefixWithZero(date.getMonth() + 1)}-${date.getFullYear()} ${prefixWithZero(date.getHours())}:${prefixWithZero(
        date.getMinutes(),
    )}:${prefixWithZero(date.getSeconds())}`;
};
