import React from 'react';
import {loadAccountState, saveAccountState} from './accountStateHelpers';
import {any, object} from 'prop-types';

const AccountStateContext = React.createContext();
const AccountDispatchContext = React.createContext();

export const accountStateReducer = (state, action) => {
    switch (action.type) {
        case 'setLoading': {
            return {
                ...state,
                loading: action.data,
            };
        }
        case 'setLoggedIn': {
            return {
                ...state,
                loggedIn: action.data,
            };
        }
        case 'setTokens': {
            saveAccountState(null, action.data);
            return {
                ...state,
                ...action.data,
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
};

const AccountStateProvider = (props) => {
    let initialData = props.defaultData;

    if (!loadAccountState()) {
        // InitialData not present - use props.defaultData
        saveAccountState(null, initialData);
    } else {
        // InitialData present - load it
        initialData = loadAccountState();
    }

    const [state, dispatch] = React.useReducer(accountStateReducer, initialData);

    return (
        <AccountStateContext.Provider value={state}>
            <AccountDispatchContext.Provider value={dispatch}>{props.children}</AccountDispatchContext.Provider>
        </AccountStateContext.Provider>
    );
};

const useAccountState = () => {
    const context = React.useContext(AccountStateContext);
    if (context === undefined) {
        throw new Error('useAccountState must be used within a AccountStateProvider');
    }
    return context;
};

const useAccountDispatch = () => {
    const context = React.useContext(AccountDispatchContext);
    if (context === undefined) {
        throw new Error('useAccountDispatch must be used within a AccountStateProvider');
    }
    return context;
};

AccountStateProvider.propTypes = {
    defaultData: object,
    children: any,
};

export {AccountStateProvider, useAccountState, useAccountDispatch};
