import React from 'react';
import {useAccountState} from 'modules/AccountState/AccountState.jsx';
import {useAppState} from 'modules/AppState/AppState.jsx';
import {AccountActivate} from 'modules/Account/AccountActivate.jsx';
import {AccountLogin} from 'modules/Account/AccountLogin.jsx';
import {AccountRegister} from 'modules/Account/AccountRegister.jsx';
import {AccountEdit} from 'modules/Account/AccountEdit.jsx';
import {translations} from './translations';

export const Account = () => {
    const {lang} = useAppState();
    const {loggedIn} = useAccountState();

    const queryString = window.location.search.replace('?', '');

    // Activate account
    if (queryString) {
        return (
            <div className="account">
                <h2 className="appSection__heading">{translations[lang].pageTitle}</h2>
                <div className="grid__wrapper">
                    <div className="grid__item grid__item--5050">
                        <AccountActivate />
                    </div>
                </div>
            </div>
        );
    }

    // General use
    return (
        <div className="account">
            <h2 className="appSection__heading">{translations[lang].pageTitle}</h2>
            <div className="grid__wrapper">
                <div className="grid__item grid__item--5050">
                    <AccountLogin />
                </div>
                <div className="grid__item grid__item--5050">{loggedIn === true ? <AccountEdit /> : <AccountRegister />}</div>
            </div>
        </div>
    );
};
