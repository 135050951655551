import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import {useAppState} from 'modules/AppState/AppState.jsx';
import {useDataDispatch} from 'modules/DataState/DataState.jsx';
import {GoPlus} from 'react-icons/go';
import {BiComment, BiCommentDetail, BiCommentCheck} from 'react-icons/bi';
import {translations} from './translations';
import {number} from 'prop-types';

export const BillsAddBill = (props) => {
    const groupIndex = props.groupIndex;
    const recordIndex = props.recordIndex;

    const {lang} = useAppState();
    const dataDispatch = useDataDispatch();
    const defaultNewBillData = {
        c: '',
        d: new Date().getTime(),
        y: new Date().getFullYear(),
        p: false,
        q: 100.25,
    };
    const [newBillData, setNewBillData] = useState(Object.assign({}, defaultNewBillData));
    const [commentVisibility, setCommentVisibility] = useState(false);

    const updateNewBillData = (key, value) => {
        let updatedNewBillData = Object.assign({}, newBillData);

        if (key === 'd') {
            // Update year on date change
            updatedNewBillData.d = value.getTime();
            updatedNewBillData.y = value.getFullYear();
        } else if (key === 'q') {
            // Update quantity
            updatedNewBillData[key] = parseFloat(value, 10);
        } else {
            // Update value by key
            updatedNewBillData[key] = value;
        }

        setNewBillData(updatedNewBillData);
    };

    const addNewBillData = () => {
        dataDispatch({type: 'addNewBillData', data: {newBillData, groupIndex, recordIndex}});
        setNewBillData(Object.assign({}, defaultNewBillData));
    };

    return (
        <tr className="bills__group-record-row bills__group-record-row--new">
            <td className="bills__group-record-cell bills__group-record-cell--q">
                <label>
                    <span className="sr-only">{translations[lang].price}</span>
                    <input
                        className="bills__group-record-input-q"
                        type="number"
                        step="10"
                        value={newBillData.q}
                        onChange={(e) => {
                            const intValRegex = /^(\d+\.\d{1,2})|(\d+\.)|(\d+)$/;
                            if (intValRegex.test(e.target.value)) {
                                updateNewBillData('q', e.target.value.match(intValRegex)[0]);
                            }
                        }}
                    />
                </label>
            </td>
            <td className="bills__group-record-cell bills__group-record-cell--d">
                <label>
                    <span className="sr-only">{translations[lang].date}</span>
                    <DatePicker
                        className="bills__group-record-input-d"
                        dateFormat="yyyy-MM-dd"
                        selected={newBillData.d}
                        onChange={(date) => {
                            updateNewBillData('d', date);
                        }}
                    />
                </label>
            </td>
            <td className="bills__group-record-cell bills__group-record-cell--p">
                <label>
                    <span className="sr-only">{translations[lang].labelMarkAsPayed}</span>
                    <input
                        type="checkbox"
                        checked={newBillData.p}
                        onChange={(e) => {
                            updateNewBillData('p', e.target.checked);
                        }}
                    />
                </label>
            </td>
            <td className={`bills__group-record-cell bills__group-record-cell--c ${commentVisibility ? 'bills__group-record-cell--c-current' : ''}`}>
                <button
                    className="bills__group-record-button bills__group-record-button-c"
                    title={`${translations[lang].comment} ${newBillData.c}`}
                    type="button"
                    rel={`textarea_${groupIndex}_${recordIndex}`}
                    onClick={(e) => {
                        let textarea = document.getElementById(e.target.attributes.rel.value);
                        setCommentVisibility(!commentVisibility);
                        setTimeout(() => {
                            textarea.focus();
                        }, 0);
                    }}
                >
                    {commentVisibility ? <BiCommentCheck /> : newBillData.c ? <BiCommentDetail /> : <BiComment />}
                </button>
                <div className={commentVisibility ? 'bills__group-record-comment' : 'bills__group-record-comment hidden'}>
                    <textarea
                        id={`textarea_${groupIndex}_${recordIndex}`}
                        className="bills__group-record-comment-textarea"
                        value={newBillData.c}
                        onChange={(e) => {
                            updateNewBillData('c', e.target.value);
                        }}
                    />
                </div>
            </td>
            <td className="bills__group-record-cell bills__group-record-cell--remove">
                <button
                    type="button"
                    title={translations[lang].labelAdd}
                    className="bills__group-record-button bills__group-record-button-remove"
                    onClick={() => {
                        addNewBillData();
                    }}
                >
                    <GoPlus />
                </button>
            </td>
        </tr>
    );
};

BillsAddBill.propTypes = {
    groupIndex: number,
    recordIndex: number,
};
