// Imports
import React from 'react';
import {AccountStateProvider} from 'modules/AccountState/AccountState.jsx';
import {AppStateProvider} from 'modules/AppState/AppState.jsx';
import {DataStateProvider} from 'modules/DataState/DataState.jsx';
import {BrowserRouter as Router} from 'react-router-dom';
import {Header} from 'modules/Header/Header.jsx';
import {PageRouter} from 'modules/PageRouter/PageRouter.jsx';

import 'src/styles/global.scss';

const version = require('../../package.json').version;
const accountStateDefaultData = require('modules/AccountState/accountStateDefaultData.json');
const appStateDefaultData = require('modules/AppState/appStateDefaultData.json');
const dataStateDefaultData = require('modules/DataState/dataStateDefaultData.json');

// Stateful components
export class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <AccountStateProvider defaultData={accountStateDefaultData}>
                <AppStateProvider defaultData={appStateDefaultData} version={version}>
                    <DataStateProvider defaultData={dataStateDefaultData}>
                        <Router>
                            <Header />
                            <main className="appSection">
                                <PageRouter />
                            </main>
                        </Router>
                    </DataStateProvider>
                </AppStateProvider>
            </AccountStateProvider>
        );
    }
}
