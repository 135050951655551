import React, {useState, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import {BillsAddBill} from 'src/modules/Bills/BillsGroupRecordsAddBill.jsx';
import {Sum} from 'modules/Global/Sum.jsx';
import {useAppState, useAppDispatch} from 'modules/AppState/AppState.jsx';
import {useDataState, useDataDispatch} from 'modules/DataState/DataState.jsx';
import {GoTrashcan} from 'react-icons/go';
import {BiComment, BiCommentDetail, BiCommentCheck} from 'react-icons/bi';
import {ImSortNumericAsc, ImSortNumbericDesc} from 'react-icons/im';
import {HiOutlineClipboardCopy, HiBadgeCheck} from 'react-icons/hi';
import {calculateTotalForBills, calculateTotalUnpayedForBills, sortBills} from 'helpers/dataHelpers';
import {dateFromTimestamp} from 'helpers/dateHelpers';
import {translations} from './translations';
import {number} from 'prop-types';

export const BillsGroupRecords = (props) => {
    const groupYear = props.groupYear;
    const groupIndex = props.groupIndex;
    const recordIndex = props.recordIndex;

    const {lang, reversed} = useAppState();
    const appDispatch = useAppDispatch();
    const {data} = useDataState();
    const dataDispatch = useDataDispatch();
    const [commentVisibility, setCommentVisibility] = useState(null);
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);
    const record = data[groupIndex].r[recordIndex];

    // Sort records by date
    const sortRecords = (reversedOrder) => {
        record.b.sort(sortBills(reversedOrder));
    };
    sortRecords(reversed);

    // Sort of reversed toggle
    useEffect(() => {
        sortRecords(reversed);
    }, [reversed]);

    const updateBillData = (i, value, key) => {
        dataDispatch({type: 'updateBillData', data: {key, value, billIndex: i, groupIndex, recordIndex}});
    };

    const removeBillData = (i) => {
        let confirm = window.confirm(translations[lang].remove);
        if (confirm) {
            dataDispatch({type: 'removeBillData', data: {billIndex: i, groupIndex, recordIndex}});
        }
    };

    const commentButtonOnClick = (i, btn) => {
        if (commentVisibility !== null && commentVisibility === i) {
            setCommentVisibility(null);
            setTimeout(() => {
                btn.focus();
            }, 0);
        } else {
            setCommentVisibility(i);
            setTimeout(() => {
                document.getElementById(btn.attributes.rel.value).focus();
            }, 0);
        }
    };

    const toggleSortOrder = () => {
        appDispatch({type: 'toggleSortOrder', data: !reversed});
    };

    return (
        <>
            <div className="bills__group-record-header">
                <h3 className="bills__group-record-name">{record.n}</h3>
                <button
                    className="bills__group-record-sort"
                    onClick={(e) => {
                        e.preventDefault();
                        toggleSortOrder();
                    }}
                >
                    {reversed ? <ImSortNumbericDesc /> : <ImSortNumericAsc />}
                    <span className="sr-only">{translations[lang].labelSort}</span>
                </button>
            </div>

            <section className={record.a || record.d ? `bills__group-record-aside` : 'hidden'}>
                {record.a && (
                    <p className="bills__group-record-aside-account" data-prefix={translations[lang].account}>
                        <label>
                            <span>{translations[lang].account}</span>
                            <input readOnly type="text" value={record.a} className="bills__group-record-account-input" />
                        </label>
                        <button
                            onClick={() => {
                                try {
                                    if (!setCopiedToClipboard(true)) {
                                        navigator.clipboard.writeText(record.a);
                                        setCopiedToClipboard(true);
                                        setTimeout(() => {
                                            setCopiedToClipboard(false);
                                        }, 2000);
                                    }
                                } catch (error) {
                                    throw new Error(`Error copying to clipboard: ${error}`);
                                }
                            }}
                            className="bills__group-record-account-copy"
                            title={translations[lang].btnCopyToClipboard}
                        >
                            <HiOutlineClipboardCopy className={`${copiedToClipboard ? 'hidden' : ''}`} />
                            <HiBadgeCheck className={`${copiedToClipboard ? '' : 'hidden'}`} />
                        </button>
                    </p>
                )}
                {record.d && (
                    <p className="bills__group-record-aside-desc">
                        {record.d.split('\n').map((item, key) => {
                            return (
                                <React.Fragment key={key}>
                                    {item}
                                    <br />
                                </React.Fragment>
                            );
                        })}
                    </p>
                )}
            </section>
            <table className="bills__group-record-table">
                <thead className="bills__group-record-heading">
                    <tr className="bills__group-record-row">
                        <th className="bills__group-record-cell">{translations[lang].price}</th>
                        <th className="bills__group-record-cell">{translations[lang].date}</th>
                        <th className="bills__group-record-cell" colSpan="3">
                            {translations[lang].options}
                        </th>
                    </tr>
                </thead>
                <tbody className="bills__group-record-body">
                    {reversed && <BillsAddBill groupIndex={groupIndex} recordIndex={recordIndex} />}
                    {record.b.map((bill, i) => {
                        if (groupYear === bill.y || groupYear === -1) {
                            return (
                                <tr key={`bill_${groupIndex}_${recordIndex}_${i}`} className={`bills__group-record-row ${bill.p ? `bills__group-record-row--payed` : ''}`}>
                                    <td className="bills__group-record-cell bills__group-record-cell--q">
                                        <label>
                                            <span className="sr-only">{translations[lang].price}</span>
                                            <input
                                                className="bills__group-record-input-q"
                                                type="number"
                                                step="10"
                                                value={bill.q}
                                                disabled={bill.p}
                                                onChange={(e) => {
                                                    const intValRegex = /^(\d+\.\d{1,2})|(\d+\.)|(\d+)$/;
                                                    if (intValRegex.test(e.target.value)) {
                                                        updateBillData(i, e.target.value.match(intValRegex)[0], 'q');
                                                    }
                                                }}
                                            />
                                        </label>
                                    </td>
                                    <td className="bills__group-record-cell bills__group-record-cell--d">
                                        <label>
                                            <span className="sr-only">{translations[lang].date}</span>
                                            <DatePicker
                                                aria-labelledby="label-date"
                                                className="bills__group-record-input-d"
                                                dateFormat="yyyy-MM-dd"
                                                selected={new Date(dateFromTimestamp(bill.d))}
                                                disabled={bill.p}
                                                onChange={(date) => {
                                                    updateBillData(i, date, 'd');
                                                }}
                                            />
                                        </label>
                                    </td>
                                    <td className="bills__group-record-cell bills__group-record-cell--p">
                                        <label>
                                            <span className="sr-only">{translations[lang].labelMarkAsPayed}</span>
                                            <input
                                                type="checkbox"
                                                checked={bill.p}
                                                onChange={(e) => {
                                                    updateBillData(i, e.target.checked, 'p');
                                                }}
                                            />
                                        </label>
                                    </td>
                                    <td className={`bills__group-record-cell bills__group-record-cell--c ${commentVisibility === i ? 'bills__group-record-cell--c-current' : ''}`}>
                                        <button
                                            className="bills__group-record-button bills__group-record-button-c"
                                            title={`${translations[lang].comment} ${bill.c}`}
                                            disabled={bill.p}
                                            type="button"
                                            rel={`textarea_${groupIndex}_${recordIndex}_${i}`}
                                            onClick={(e) => {
                                                if (!bill.p) {
                                                    commentButtonOnClick(i, e.target);
                                                }
                                            }}
                                        >
                                            {commentVisibility === i ? <BiCommentCheck /> : bill.c ? <BiCommentDetail /> : <BiComment />}
                                        </button>
                                        <div className={commentVisibility === i ? 'bills__group-record-comment' : 'bills__group-record-comment hidden'}>
                                            <textarea
                                                id={`textarea_${groupIndex}_${recordIndex}_${i}`}
                                                className="bills__group-record-comment-textarea"
                                                value={bill.c}
                                                onChange={(e) => {
                                                    updateBillData(i, e.target.value, 'c');
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td className="bills__group-record-cell bills__group-record-cell--remove">
                                        <button
                                            type="button"
                                            title={translations[lang].labelRemove}
                                            disabled={bill.p}
                                            className="bills__group-record-button bills__group-record-button-remove"
                                            onClick={() => {
                                                removeBillData(i);
                                            }}
                                        >
                                            <GoTrashcan />
                                        </button>
                                    </td>
                                </tr>
                            );
                        }
                    })}
                    <tr className="bills__group-record-row">
                        <td className="bills__group-record-cell">
                            <Sum value={calculateTotalForBills(record.b, groupYear)} unpayedValue={calculateTotalUnpayedForBills(record.b, groupYear)} />
                        </td>
                        <td colSpan="4" className="bills__group-record-cell"></td>
                    </tr>
                    {!reversed && <BillsAddBill groupIndex={groupIndex} recordIndex={recordIndex} />}
                </tbody>
            </table>
        </>
    );
};

BillsGroupRecords.propTypes = {
    groupIndex: number,
    groupYear: number,
    recordIndex: number,
};
