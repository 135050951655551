import React, {useState} from 'react';
import {useAppState} from 'modules/AppState/AppState.jsx';
import {readableDateFromTimestamp} from './msgConsoleHelpers';
import {GrNotes} from 'react-icons/gr';
import {translations} from './translations';

export const MsgConsole = () => {
    const {messages, lang} = useAppState();
    const [consoleExpanded, setConsoleExpanded] = useState(false);

    return (
        <section className={`msgConsole ${consoleExpanded ? 'msgConsole--expanded' : 'msgConsole--collapsed'}`}>
            <button
                title={`${translations[lang].logs}`}
                className="msgConsole__button"
                onClick={() => {
                    setConsoleExpanded(!consoleExpanded);
                }}
            >
                <GrNotes className="msgConsole__button-icon" />
            </button>
            <ul className="msgConsole__list">
                {messages
                    .slice()
                    .reverse()
                    .map((msg, i) => {
                        return (
                            <li className="msgConsole__item" key={`msg_${i}`}>
                                <span className="msgConsole__time">{readableDateFromTimestamp(msg.time)}</span>
                                <span className="msgConsole__text">{msg.text}</span>
                            </li>
                        );
                    })}
            </ul>
        </section>
    );
};
