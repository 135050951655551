import React from 'react';
import {useAppState} from 'modules/AppState/AppState.jsx';
import {number} from 'prop-types';
import {translations} from './translations';

export const Sum = (props) => {
    const value = props.value;
    const unpayedValue = props.unpayedValue;
    const {lang} = useAppState();

    return (
        <>
            <span className="global__sum-icon" title={translations[lang].sum}>
                &sum;
            </span>
            &nbsp;
            <span className="global__sum-value">{unpayedValue ? `${unpayedValue} / ${value}` : value}</span>
        </>
    );
};

Sum.propTypes = {
    value: number,
    unpayedValue: number,
};
