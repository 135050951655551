import React from 'react';
import {useAppState} from 'modules/AppState/AppState.jsx';
import {useDataState} from 'modules/DataState/DataState.jsx';
import {DashboardUnpaid} from 'modules/Dashboard/DashboardUnpaid.jsx';
import {translations} from './translations';

export const Dashboard = () => {
    const {lang} = useAppState();
    const {data} = useDataState();

    return (
        <div className="dashboard">
            <h2 className="appSection__heading">{translations[lang].pageTitle}</h2>
            <div className="grid__wrapper">
                <div className="grid__item grid__item--5050">
                    {data.map((groupData, i) => {
                        return <DashboardUnpaid dataGroup={groupData} key={`groupData_${i}`} />;
                    })}
                </div>
            </div>
        </div>
    );
};
