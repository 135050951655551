import React from 'react';
import {useAppState} from 'modules/AppState/AppState.jsx';
import {useDataState} from 'modules/DataState/DataState.jsx';
import {dateFromTimestamp} from 'helpers/dateHelpers';
import {sortBills} from 'helpers/dataHelpers';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts';
import {number} from 'prop-types';

export const ChartsGroupChart = (props) => {
    const groupYear = props.groupYear;
    const groupIndex = props.groupIndex;
    const recordIndex = props.recordIndex;

    const {reversed} = useAppState();
    const {data} = useDataState();
    const record = data[groupIndex].r[recordIndex];

    // Change sorting for charts if is set as reversed so that charts are always asc in date
    if (reversed) {
        record.b.sort(sortBills(!reversed));
    }

    const buildChartData = (groupYear) => {
        const recordBills = record.b
            .filter((bill) => {
                return groupYear === -1 ? true : bill.y === groupYear;
            })
            .map((bill) => {
                return {
                    name: dateFromTimestamp(bill.d),
                    val: parseFloat(bill.q, 10),
                };
            });

        return recordBills;
    };

    return (
        <div className="charts__group-chart">
            <h3>{record.n}</h3>
            <ResponsiveContainer>
                <LineChart
                    width={500}
                    height={300}
                    data={buildChartData(groupYear)}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />

                    <Line type="monotone" dataKey="val" stroke="#8884d8" activeDot={{r: 8}} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

ChartsGroupChart.propTypes = {
    groupIndex: number,
    groupYear: number,
    recordIndex: number,
};
