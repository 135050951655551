export const translations = {
    PL: {
        pageTitle: 'Rachunki',
        price: 'Kwota',
        date: 'Data',
        payed: 'Zapłacone',
        options: 'Opcje',
        account: 'Nr konta: ',
        remove: 'Usunąć dany wpis?',
        all: 'Wszystkie',
        comment: 'Komentarz:',
        btnCopyToClipboard: 'Skopiuj do schowka',
        labelMarkAsPayed: 'Oznacz jako opłacone',
        labelRemove: 'Usuń',
        labelAdd: 'Dodaj',
        labelSort: 'Zmień kolejność sortowania',
    },
    ENG: {
        pageTitle: 'Bills',
        price: 'Price',
        date: 'Date',
        payed: 'Paid',
        options: 'Options',
        account: 'Account nr : ',
        remove: 'Remove this entry?',
        all: 'All',
        comment: 'Comment:',
        btnCopyToClipboard: 'Copy to clipbloard',
        labelMarkAsPayed: 'Mark as paid',
        labelRemove: 'Remove',
        labelAdd: 'Add',
        labelSort: 'Change sort order',
    },
};
