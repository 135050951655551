export const translations = {
    PL: {
        pageTitle: 'Podsumowanie',
        unpaidTitle: 'Nieopłacone rachunki',
        group: 'Grupa',
        record: 'Rekord',
        date: 'Data',
        price: 'Kwota',
        empty: 'Brak nieopłaconych rachunków',
    },
    ENG: {
        pageTitle: 'Dashboard',
        unpaidTitle: 'Unpaid bills',
        group: 'Group',
        record: 'Record',
        date: 'Date',
        price: 'Price',
        empty: 'There are no unpaid bills',
    },
};
