import React, {useEffect} from 'react';
import {useAccountState, useAccountDispatch} from 'modules/AccountState/AccountState.jsx';
import {useAppState} from 'modules/AppState/AppState.jsx';
import {checkLoginStatus, refreshTokens} from 'helpers/authHelpers';
import {RiAccountCircleLine, RiAccountCircleFill} from 'react-icons/ri';
import {VscLoading} from 'react-icons/vsc';
import {translations} from './translations';

const accountStateDefaultData = require('modules/AccountState/accountStateDefaultData.json');

export const AccountIcon = () => {
    const {lang} = useAppState();
    const accountDispatch = useAccountDispatch();
    const {loading, loggedIn, userId, accessToken, refreshToken} = useAccountState();

    const errorHandler = () => {
        accountDispatch({type: 'setTokens', data: accountStateDefaultData});
    };

    useEffect(() => {
        if (userId) {
            accountDispatch({type: 'setLoading', data: true});
            checkLoginStatus(`${process.env.NETLIFY_FUNCTIONS_URL}${process.env.NETLIFY_FUNCTION_CHECKLOGIN_URL}`, userId, accessToken).then((response) => {
                // Is logged in
                if (response === true) {
                    accountDispatch({type: 'setLoading', data: false});
                    accountDispatch({type: 'setLoggedIn', data: true});
                } else {
                    // No data recievied
                    if (response.data === null) {
                        // Error getting data from netlify function - fetch failed - logout
                        errorHandler();
                    } else if (response.data.accessToken === false || response.data.expiryDate === false) {
                        // Get new tokens
                        refreshTokens(`${process.env.NETLIFY_FUNCTIONS_URL}${process.env.NETLIFY_FUNCTION_REFRESHTOKENS_URL}`, userId, refreshToken).then((response) => {
                            if (response.data === null) {
                                // Error getting data from netlify function - fetch failed - logout
                                errorHandler();
                            } else if (response?.data?.accessToken) {
                                // Got new tokens
                                let tokenData = response.data;
                                tokenData.loading = false;
                                tokenData.loggedIn = false;
                                accountDispatch({type: 'setTokens', data: tokenData});

                                // Check login once again after getting new tokens
                                checkLoginStatus(
                                    `${process.env.NETLIFY_FUNCTIONS_URL}${process.env.NETLIFY_FUNCTION_CHECKLOGIN_URL}`,
                                    response.data.userId,
                                    response.data.accessToken,
                                ).then((response) => {
                                    // Is logged in
                                    if (response === true) {
                                        accountDispatch({type: 'setLoading', data: false});
                                        accountDispatch({type: 'setLoggedIn', data: true});
                                    } else {
                                        // TODO: other error
                                        accountDispatch({type: 'setLoading', data: false});
                                        accountDispatch({type: 'setLoggedIn', data: false});
                                    }
                                });
                            } else if (response.data.refreshToken === false) {
                                // Wrong refresh token - logout
                                errorHandler();
                            } else {
                                // Other error - logout
                                errorHandler();
                            }
                        });
                    } else {
                        // Other error - logout
                        errorHandler();
                    }
                }
            });
        }
    }, [userId]);

    // Is loading
    if (loading || undefined === loading) {
        return (
            <>
                <VscLoading className="header__nav-list-icon spin" />
                <span className="header__nav-list-text">{translations[lang].loading}</span>
            </>
        );
    }

    // Isn't loading
    return (
        <>
            {loggedIn ? <RiAccountCircleFill className="header__nav-list-icon" /> : <RiAccountCircleLine className="header__nav-list-icon" />}
            <span className="header__nav-list-text">{translations[lang].linkAccount}</span>
        </>
    );
};
