export const translations = {
    PL: {
        pageTitle: 'Ustawienia',
        removeText: 'Usuń',
        removeGroupPrompt: 'Tej operacji nie da się cofnąć. Razem z grupą zostaną usunięte wszystkie powiązane z nią dane.',
        recordName: 'Nazwa',
        recordAccount: 'Nr konta',
        recordDescription: 'Opis',
        recordCount: 'Ilość wpisów',
        recordLatest: 'Ostatni wpis',
        recordDaysAgo: 'dni temu',
        removeGroupRecordPrompt: 'Tej operacji nie da się cofnąć. Zostaną usunięte wszystkie wpisy dla ',
        addText: 'Dodaj',
        groupName: 'Wybierz grupę/zmień nazwę',
        newGroup: 'Nowa grupa',
    },
    ENG: {
        pageTitle: 'Settings',
        removeText: 'Remove',
        removeGroupPrompt: 'This operation cannot be undone. All data associated with the group will be deleted along with the group.',
        recordName: 'Name',
        recordAccount: 'Bank account number',
        recordDescription: 'Description',
        recordCount: 'Number of entries',
        recordLatest: 'Latest entry',
        recordDaysAgo: 'days ago',
        removeGroupRecordPrompt: 'This operation cannot be undone. All entries on that will be deleted for ',
        addText: 'Add',
        groupName: 'Pick group/change group name',
        newGroup: 'New group',
    },
};
