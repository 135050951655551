import React, {useState} from 'react';
import {useAppState} from 'modules/AppState/AppState.jsx';
import {GroupPicker} from 'modules/Global/GroupPicker.jsx';
import {BillsGroup} from 'modules/Bills/BillsGroup.jsx';
import {translations} from './translations';

export const Bills = () => {
    const {lang} = useAppState();
    const [expandedGroup, setExpandedGroup] = useState(0);

    return (
        <div className="bills">
            <h2 className="appSection__heading">{translations[lang].pageTitle}</h2>
            <GroupPicker setExpandedGroup={setExpandedGroup} expandedGroup={expandedGroup} />
            <BillsGroup expandedGroup={expandedGroup} />
        </div>
    );
};
