import React, {useState, useEffect} from 'react';
import {submitRegister} from 'helpers/authHelpers';
import {useAppState} from 'modules/AppState/AppState.jsx';
import {useAccountState, useAccountDispatch} from 'modules/AccountState/AccountState.jsx';
import {VscLoading} from 'react-icons/vsc';
import {RiMapPinUserLine, RiLockPasswordLine, RiMailLine} from 'react-icons/ri';
import {translations} from './translations';

export const AccountRegister = () => {
    const {lang} = useAppState();
    const accountDispatch = useAccountDispatch();
    const {loading} = useAccountState();

    const [registerInProgress, setRegisterInProgress] = useState(false);
    const [login, setLogin] = useState('');
    const [email, setEmail] = useState('');
    const [email2, setEmail2] = useState('');
    const [passwd, setPasswd] = useState('');
    const [passwd2, setPasswd2] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [registerData, setRegisterData] = useState({error: false, data: {}});
    const [registered, setRegistered] = useState(false);

    useEffect(() => {
        validateRegistrationForm();
        handleErrorMsg(registerData);
    }, [lang]);

    const validateRegistrationForm = () => {
        let valid = true;

        // Empty form
        if (!login && !email && !passwd) {
            setErrorMsg('');
            return false;
        }

        // Form filled
        if (!login) {
            setErrorMsg(translations[lang].errorUserId);
            valid = false;
        } else if (!email || email !== email2) {
            setErrorMsg(translations[lang].errorEmail);
            valid = false;
        } else if (!passwd || passwd !== passwd2) {
            setErrorMsg(translations[lang].errorPassword);
            valid = false;
        }

        return valid;
    };

    const submitRegisterForm = (e) => {
        e.preventDefault();
        if (validateRegistrationForm() && !registerInProgress) {
            setRegisterInProgress(true);
            accountDispatch({type: 'setLoading', data: true});
            submitRegister(`${process.env.NETLIFY_FUNCTIONS_URL}${process.env.NETLIFY_FUNCTION_REGISTER_URL}`, login, email, passwd).then((response) => {
                if (response.error === false) {
                    setErrorMsg('');
                    setRegistered(true);
                } else {
                    handleErrorMsg(response.data);
                    setRegisterData(response.data);
                }
                accountDispatch({type: 'setLoading', data: false});
                setRegisterInProgress(false);
            });
        }
    };

    const handleErrorMsg = (data) => {
        if (data === null) {
            setErrorMsg(translations[lang].errorFetch);
        } else if (data.login === false) {
            setErrorMsg(translations[lang].errorUserId);
        } else if (data.email === false) {
            setErrorMsg(translations[lang].errorEmail);
        } else {
            setErrorMsg('');
        }
    };

    // Is loading
    if (loading) {
        return (
            <div className="account__register">
                <h3 className="appSection__heading">{translations[lang].registerHeader}</h3>
                <div className="account__register-wrapper">
                    <VscLoading className="spin" />
                </div>
            </div>
        );
    }

    // Account registered
    if (registered) {
        return (
            <div className="account__register">
                <h3 className="appSection__heading">{translations[lang].registerHeader}</h3>
                <div className="account__register-wrapper">{translations[lang].registerSuccess}</div>
            </div>
        );
    }

    // Registration form
    if (!registered) {
        return (
            <div className="account__register">
                {/* <h3 className="appSection__heading">{translations[lang].registerHeader}</h3>
                <div className="account__register-wrapper">
                    <form
                        className="account__register-form"
                        onSubmit={(e) => {
                            submitRegisterForm(e);
                        }}
                    >
                        <p className="account__register-error">{errorMsg}</p>
                        <label htmlFor="loginRegisterInput" className="account__register-label">
                            <span className="account__register-text">{translations[lang].loginLabel}</span>
                            <RiMapPinUserLine className="account__register-icon" aria-hidden="true" />
                            <input
                                id="loginRegisterInput"
                                type="text"
                                value={login}
                                className="account__register-field"
                                onChange={(e) => {
                                    setLogin(e.target.value);
                                }}
                            />
                        </label>
                        <label htmlFor="emailRegisterInput" className="account__register-label">
                            <span className="account__register-text">{translations[lang].emailLabel}</span>
                            <RiMailLine className="account__register-icon" aria-hidden="true" />
                            <input
                                id="emailRegisterInput"
                                type="text"
                                value={email}
                                className="account__register-field"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </label>
                        <label htmlFor="emailRegisterInput2" className="account__register-label">
                            <span className="account__register-text">{translations[lang].emailLabelRep}</span>
                            <RiMailLine className="account__register-icon" aria-hidden="true" />
                            <input
                                id="emailRegisterInput2"
                                type="text"
                                value={email2}
                                className="account__register-field"
                                onChange={(e) => {
                                    setEmail2(e.target.value);
                                }}
                            />
                        </label>
                        <label htmlFor="passwdRegisterInput" className="account__register-label">
                            <span className="account__register-text">{translations[lang].passwdLabel}</span>
                            <RiLockPasswordLine className="account__register-icon" aria-hidden="true" />
                            <input
                                id="passwdRegisterInput"
                                type="password"
                                value={passwd}
                                className="account__register-field"
                                onChange={(e) => {
                                    setPasswd(e.target.value);
                                }}
                            />
                        </label>
                        <label htmlFor="passwdRegisterInput2" className="account__register-label">
                            <span className="account__register-text">{translations[lang].passwdLabelRep}</span>
                            <RiLockPasswordLine className="account__register-icon" aria-hidden="true" />
                            <input
                                id="passwdRegisterInput2"
                                type="password"
                                value={passwd2}
                                className="account__register-field"
                                onChange={(e) => {
                                    setPasswd2(e.target.value);
                                }}
                            />
                        </label>
                        <button type="submit" className="global__groups-button account__login-submit">
                            {translations[lang].registerSubmitText}
                        </button>
                    </form>
                </div> */}
            </div>
        );
    }
};
