import React, {useState} from 'react';
import {useAppState} from 'modules/AppState/AppState.jsx';
import {SettingsGroupPicker} from './SettingsGroupPicker.jsx';
import {SettingsGroup} from './SettingsGroup.jsx';
import {translations} from './translations';

export const Settings = () => {
    const {lang} = useAppState();
    const [expandedGroup, setExpandedGroup] = useState(0);

    return (
        <div className="settings">
            <h2 className="appSection__heading">{translations[lang].pageTitle}</h2>
            <SettingsGroupPicker setExpandedGroup={setExpandedGroup} expandedGroup={expandedGroup} />
            <SettingsGroup setExpandedGroup={setExpandedGroup} expandedGroup={expandedGroup} />
        </div>
    );
};
