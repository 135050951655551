import {getFromStorage, setToStorage} from 'helpers/localStorageHelpers';

/**
 * Load accountState data from local storage
 * @param {string} key optional: key value
 * @returns {object} accountState data from local storage
 */
export const loadAccountState = (key) => {
    return getFromStorage('billableAccountState', key);
};

/**
 * Save accountState data to local storage
 * @param {string} key optional: key value to set
 * @param {any} value value to set - if not provided will set to entire appState
 * @returns {object} entire accountState data object
 */
export const saveAccountState = (key, value) => {
    return setToStorage('billableAccountState', key, value);
};
