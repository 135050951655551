import React from 'react';
import {loadAppState, saveAppState} from './appStateHelpers';
import {any, object, string} from 'prop-types';

const AppStateContext = React.createContext();
const AppDispatchContext = React.createContext();

export const appStateReducer = (state, action) => {
    switch (action.type) {
        case 'setLanguage': {
            saveAppState('lang', action.data);
            return {
                ...state,
                lang: action.data,
            };
        }
        case 'toggleMenu': {
            saveAppState('menuExpanded', action.data);
            return {
                ...state,
                menuExpanded: action.data,
            };
        }
        case 'toggleSortOrder': {
            saveAppState('reversed', action.data);
            return {
                ...state,
                reversed: action.data,
            };
        }
        case 'setMsgConsole': {
            saveAppState('messages', action.data);
            return {
                ...state,
                messages: action.data,
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
};

const AppStateProvider = (props) => {
    const version = props.version;
    let initialData = props.defaultData;

    if (!loadAppState()) {
        // InitialData not present - use props.defaultData
        initialData.version = version;
        saveAppState(null, initialData);
    } else {
        // InitialData present - load it
        initialData = loadAppState();
        initialData.version = version;
        saveAppState(null, initialData);
    }

    const [state, dispatch] = React.useReducer(appStateReducer, initialData);

    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>{props.children}</AppDispatchContext.Provider>
        </AppStateContext.Provider>
    );
};

const useAppState = () => {
    const context = React.useContext(AppStateContext);
    if (context === undefined) {
        throw new Error('useAppState must be used within a AppStateProvider');
    }
    return context;
};

const useAppDispatch = () => {
    const context = React.useContext(AppDispatchContext);
    if (context === undefined) {
        throw new Error('useAppDispatch must be used within a AppStateProvider');
    }
    return context;
};

AppStateProvider.propTypes = {
    defaultData: object,
    children: any,
    version: string,
};

export {AppStateProvider, useAppState, useAppDispatch};
