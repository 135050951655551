import React from 'react';
import {useDataState} from 'modules/DataState/DataState.jsx';
import {func, number} from 'prop-types';

export const GroupPicker = (props) => {
    const {data} = useDataState();

    return (
        <ul className="global__groups-list">
            {data.map((group, i) => {
                return (
                    <li key={`g_${i}`} className="global__groups-item">
                        <button
                            className={`global__groups-button ${i == props.expandedGroup ? 'global__groups-button--current' : ''}`}
                            type="button"
                            onClick={() => {
                                props.setExpandedGroup(i);
                            }}
                        >
                            {group.n}
                        </button>
                    </li>
                );
            })}
        </ul>
    );
};

GroupPicker.propTypes = {
    setExpandedGroup: func,
    expandedGroup: number,
};
