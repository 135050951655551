/**
 * Get bill years from a given group by group index
 * @param {array} data current appData.data
 * @param {number} groupIndex index of group
 * @returns {array} array of available years sorted desc
 */
export const getYearsForGroup = (data, groupIndex) => {
    let years = new Set();

    data[groupIndex].r.forEach((rec) => {
        rec.b.forEach((bill) => {
            years.add(bill.y);
        });
    });

    return [...years, -1].sort((a, b) => b - a);
};

/**
 * Calculate total q for bills array in a given year
 * @param {array} bills bills array
 * @param {number} groupYear year or -1 for all years
 * @returns {number} calculated total
 */
export const calculateTotalForBills = (bills, groupYear) => {
    return (
        Math.round(
            bills
                .filter((bill) => {
                    return groupYear === -1 ? bill : bill.y === groupYear;
                })
                .reduce((total, bill) => {
                    return total + bill.q;
                }, 0) * 100,
        ) / 100
    );
};

/**
 * Calculate total q for unpayed bills array in a given year
 * @param {array} bills bills array
 * @param {number} groupYear year or -1 for all years
 * @returns {number} calculated total
 */
export const calculateTotalUnpayedForBills = (bills, groupYear) => {
    return (
        Math.round(
            bills
                .filter((bill) => {
                    return groupYear === -1 ? bill : bill.y === groupYear;
                })
                .filter((bill) => {
                    return !bill.p;
                })
                .reduce((total, bill) => {
                    return total + bill.q;
                }, 0) * 100,
        ) / 100
    );
};

/**
 * Remove payed bills from data
 * @description Will remove payed bills. then records without unpaid bills, then groups that don't have records
 * @param {array} data current appData.data
 * @returns {array} data without payed bills
 */
export const removePayedBills = (data) => {
    return data
        .map((group) => {
            let _group = Object.assign({}, group);
            _group.r = group.r
                .map((record) => {
                    let _record = Object.assign({}, record);
                    _record.b = _record.b.filter((bill) => {
                        return !bill.p;
                    });
                    return _record;
                })
                .filter((record) => {
                    return record.b.length;
                });
            return _group;
        })
        .filter((group) => {
            return group.r.length;
        });
};

/**
 * Sort bills by date
 * @param {boolean} reversedOrder true for desc false for asc
 * @returns {function} sorting function
 */
export const sortBills = (reversedOrder) => {
    if (reversedOrder) {
        return (a, b) => {
            if (a.d > b.d) return -1;
            if (a.d < b.d) return 1;
            return 0;
        };
    } else {
        return (a, b) => {
            if (a.d < b.d) return -1;
            if (a.d > b.d) return 1;
            return 0;
        };
    }
};
