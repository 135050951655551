import React from 'react';
import {useAppState} from 'modules/AppState/AppState.jsx';
import {useDataState, useDataDispatch} from 'modules/DataState/DataState.jsx';
import {translations} from './translations';
import {GoTrashcan} from 'react-icons/go';
import {func, number, string} from 'prop-types';

export const SettingsGroupRemove = (props) => {
    const {lang} = useAppState();
    const {data} = useDataState();
    const dataDispatch = useDataDispatch();

    const removeGroup = (groupName, groupIndex) => {
        let confirmRemove = confirm(translations[lang].removeGroupPrompt);
        if (confirmRemove) {
            dataDispatch({type: 'removeGroup', data: {name: groupName, index: groupIndex}});
            props.setExpandedGroup(0);
        }
    };

    return (
        <div className="settings__group-options ">
            <button
                className="settings__group-remove"
                disabled={data.length < 2}
                onClick={() => {
                    removeGroup(props.groupName, props.groupIndex);
                }}
            >
                <GoTrashcan className="settings__group-remove-icon" /> {translations[lang].removeText} <strong>{props.groupName}</strong>
            </button>
        </div>
    );
};

SettingsGroupRemove.propTypes = {
    setExpandedGroup: func,
    groupName: string,
    groupIndex: number,
};
