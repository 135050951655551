/**
 * Cut timestamp to day - hh:mm:ss === 00:00:00
 * @param {number|string} timestamp unix timestamp
 * @returns {number} day timestamp
 */
export const cutTimestampToDays = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    const cutTimestamp = new Date(`${date.getFullYear()},${date.getMonth()},${date.getDate()}`).getTime();

    return cutTimestamp;
};

/**
 * Print date from timestamp
 * @description When timestamp is not provided will return current date
 * @param {number|string|undefined} timestamp unix timestamp
 * @returns {string} date in dd-mm-yyyy format
 */
export const dateFromTimestamp = (timestamp) => {
    const date = timestamp ? new Date(parseInt(timestamp, 10)) : new Date();
    const day = `${date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`}`;
    const month = `${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`}`;
    const year = `${date.getFullYear()}`;

    return `${year}-${month}-${day}`;
};

/**
 * Show diff in days agains given timestamp
 * @param {number|string} timestamp unix timestamp to count diff against
 * @returns {string} +/- days string
 */
export const daysDiffFromNow = (timestamp) => {
    const currentTimestamp = cutTimestampToDays(new Date().getTime());
    const givenTimestamp = cutTimestampToDays(parseInt(timestamp, 10));
    const diff = currentTimestamp - givenTimestamp;
    return `${diff < 0 ? `-${Math.floor(diff / (1000 * 60 * 60 * 24))}` : `+${Math.floor(diff / (1000 * 60 * 60 * 24))}`}`;
};

/**
 * Create save file name with current date
 * @returns {string} file name with date
 */
export const getSaveFileName = () => {
    const now = new Date();
    const year = `${now.getFullYear()}`;
    const month = `${now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : `${now.getMonth() + 1}`}`;
    const day = `${now.getDate() < 10 ? `0${now.getDate()}` : `${now.getDate()}`}`;
    const hour = `${now.getHours() < 10 ? `0${now.getHours()}` : `${now.getHours()}`}`;
    const minutes = `${now.getMinutes() < 10 ? `0${now.getMinutes()}` : `${now.getMinutes()}`}`;

    return `billable_${year}-${month}-${day}(${hour}_${minutes}).json`;
};
