export const translations = {
    PL: {
        appName: 'Płatności',
        linkDashboard: 'Podsumowanie',
        linkBills: 'Rachunki',
        linkCharts: 'Wykresy',
        linkSettings: 'Ustawienia',
        linkAccount: 'Konto',
        save: 'Zapisz',
        load: 'Wczytaj',
        switchTitlePL: 'Polska wersja językowa',
        switchTitleENG: 'English language version',
        homepage: 'Strona główna',
        toggleMenuSize: 'Przełącz rozmiar menu',
    },
    ENG: {
        appName: 'Billable',
        linkDashboard: 'Dashboard',
        linkBills: 'Bills',
        linkCharts: 'Charts',
        linkSettings: 'Settings',
        linkAccount: 'Account',
        save: 'Save',
        load: 'Load',
        switchTitlePL: 'Polska wersja językowa',
        switchTitleENG: 'English language version',
        homepage: 'Homepage',
        toggleMenuSize: 'Toggle menu size',
    },
};
