import React from 'react';
import {HuePicker} from 'react-color';
import {useAppState} from 'modules/AppState/AppState.jsx';
import {useDataDispatch} from 'modules/DataState/DataState.jsx';
import {dateFromTimestamp, daysDiffFromNow} from 'helpers/dateHelpers';
import {translations} from './translations';
import {GoTrashcan} from 'react-icons/go';
import {object, number, string} from 'prop-types';

export const SettingsGroupRecord = (props) => {
    const {lang} = useAppState();
    const dataDispatch = useDataDispatch();

    const removeGroupRecord = (groupName, groupIndex, recordName, recordIndex) => {
        let confirmRemove = confirm(`${translations[lang].removeGroupRecordPrompt} ${groupName}/${recordName}`);
        if (confirmRemove) {
            dataDispatch({type: 'removeGroupRecord', data: {groupName: groupName, groupIndex: groupIndex, recordName: recordName, recordIndex: recordIndex}});
        }
    };

    const updateRecordData = (key, groupIndex, recordIndex, newValue) => {
        dataDispatch({type: 'updateRecord', data: {key: key, groupIndex: groupIndex, recordIndex: recordIndex, newValue: newValue}});
    };

    return (
        <li className="grid__item">
            <div className="grid__item-inner" style={{borderColor: props.record.c, backgroundColor: `${props.record.c}11`}}>
                <div className="settings__group-item-form">
                    <div className="settings__group-item-fieldset">
                        <label htmlFor={`rec_n_${props.groupIndex}_${props.recordIndex}`} className="settings__group-item-label">
                            {translations[lang].recordName}
                        </label>
                        <input
                            id={`rec_n_${props.groupIndex}_${props.recordIndex}`}
                            type="text"
                            value={props.record.n}
                            className="settings__group-item-input"
                            onChange={(e) => {
                                const newValue = e.target.value;
                                updateRecordData('n', props.groupIndex, props.recordIndex, newValue);
                            }}
                        />
                    </div>

                    <div className="settings__group-item-fieldset">
                        <label htmlFor={`rec_a_${props.groupIndex}_${props.recordIndex}`} className="settings__group-item-label">
                            {translations[lang].recordAccount}
                        </label>
                        <input
                            id={`rec_a_${props.groupIndex}_${props.recordIndex}`}
                            type="text"
                            value={props.record.a}
                            className="settings__group-item-input"
                            onChange={(e) => {
                                const newValue = e.target.value;

                                if (/^[0-9]{0,26}$/.test(newValue)) {
                                    updateRecordData('a', props.groupIndex, props.recordIndex, newValue);
                                } else {
                                    return false;
                                }
                            }}
                        />
                    </div>

                    <div className="settings__group-item-fieldset">
                        <HuePicker
                            color={props.record.c}
                            onChangeComplete={(color) => {
                                updateRecordData('c', props.groupIndex, props.recordIndex, color.hex);
                            }}
                        />
                    </div>

                    <div className="settings__group-item-fieldset">
                        <label htmlFor={`rec_d_${props.groupIndex}_${props.recordIndex}`} className="settings__group-item-label">
                            {translations[lang].recordDescription}
                        </label>
                        <textarea
                            id={`rec_d_${props.groupIndex}_${props.recordIndex}`}
                            type="text"
                            value={props.record.d}
                            className="settings__group-item-textarea"
                            onChange={(e) => {
                                const newValue = e.target.value;
                                updateRecordData('d', props.groupIndex, props.recordIndex, newValue);
                            }}
                        ></textarea>
                    </div>

                    <ul className="settings__group-item-details">
                        <li className="settings__group-item-detail">
                            {translations[lang].recordCount}: <strong>{props.record.b.length}</strong>
                        </li>
                        {props.record.b.length > 0 && (
                            <li className="settings__group-item-detail">
                                {translations[lang].recordLatest}: <strong>{dateFromTimestamp(props.record.b[props.record.b.length - 1].d)}</strong> (
                                {daysDiffFromNow(props.record.b[props.record.b.length - 1].d)}
                                {` ${translations[lang].recordDaysAgo}`})
                            </li>
                        )}
                    </ul>
                </div>

                <div className="settings__group-item-options">
                    <button
                        className="settings__group-item-remove"
                        onClick={() => {
                            removeGroupRecord(props.groupName, props.groupIndex, props.record.n, props.recordIndex);
                        }}
                    >
                        <GoTrashcan className="settings__group-item-remove-icon" />
                        {translations[lang].removeText}&nbsp;<strong>{props.record.n}</strong>
                    </button>
                </div>
            </div>
        </li>
    );
};

SettingsGroupRecord.propTypes = {
    groupName: string,
    record: object,
    groupIndex: number,
    recordIndex: number,
};
