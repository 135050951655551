import React from 'react';
import {useAppState, useAppDispatch} from 'modules/AppState/AppState.jsx';
import {MsgConsole} from 'modules/MsgConsole/MsgConsole.jsx';
import {translations} from './translations';

export const HeaderBottom = () => {
    const {lang, version} = useAppState();
    const appDispatch = useAppDispatch();

    return (
        <div className="header__bottom">
            <section className="header__bottom-section header__bottom-section--buttons">
                <button
                    className={lang === 'PL' ? 'header__lang header__lang--selected' : 'header__lang'}
                    title={translations[lang].switchTitlePL}
                    onClick={() => appDispatch({type: 'setLanguage', data: 'PL'})}
                >
                    PL
                </button>
                <button
                    className={lang === 'ENG' ? 'header__lang header__lang--selected' : 'header__lang'}
                    title={translations[lang].switchTitleENG}
                    onClick={() => appDispatch({type: 'setLanguage', data: 'ENG'})}
                >
                    ENG
                </button>
            </section>
            <section className="header__bottom-section header__bottom-section--version">
                <span className="header_bottom-version">{version}</span>
            </section>

            <MsgConsole />
        </div>
    );
};
