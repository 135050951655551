import React from 'react';
import {
    loadDataState,
    saveDataState,
    addNewGroup,
    removeGroup,
    renameGroup,
    updateRecord,
    removeGroupRecord,
    addGroupRecord,
    updateBillData,
    removeBillData,
    addNewBillData,
} from './dataStateHelpers';
import {any, object} from 'prop-types';

const DataStateContext = React.createContext();
const DataDispatchContext = React.createContext();

export const dataStateReducer = (state, action) => {
    // Save last action timestamp
    const now = Math.round(new Date().getTime() / 1000);
    saveDataState('timestamp', now);

    switch (action.type) {
        case 'addGroup': {
            const newAppData = addNewGroup(state.data, action.data);
            saveDataState('data', newAppData);

            return {
                ...state,
                data: newAppData,
                timestamp: now,
            };
        }
        case 'removeGroup': {
            const newAppData = removeGroup(state.data, action.data);
            saveDataState('data', newAppData);
            return {
                ...state,
                data: newAppData,
                timestamp: now,
            };
        }
        case 'renameGroup': {
            const newAppData = renameGroup(state.data, action.data);
            saveDataState('data', newAppData);
            return {
                ...state,
                data: newAppData,
                timestamp: now,
            };
        }
        case 'updateRecord': {
            const newAppData = updateRecord(state.data, action.data);
            saveDataState('data', newAppData);
            return {
                ...state,
                data: newAppData,
                timestamp: now,
            };
        }
        case 'removeGroupRecord': {
            const newAppData = removeGroupRecord(state.data, action.data);
            saveDataState('data', newAppData);
            return {
                ...state,
                data: newAppData,
                timestamp: now,
            };
        }
        case 'addGroupRecord': {
            const newAppData = addGroupRecord(state.data, action.data);
            saveDataState('data', newAppData);
            return {
                ...state,
                data: newAppData,
                timestamp: now,
            };
        }
        case 'updateBillData': {
            const newAppData = updateBillData(state.data, action.data);
            saveDataState('data', newAppData);
            return {
                ...state,
                data: newAppData,
                timestamp: now,
            };
        }
        case 'removeBillData': {
            const newAppData = removeBillData(state.data, action.data);
            saveDataState('data', newAppData);
            return {
                ...state,
                data: newAppData,
                timestamp: now,
            };
        }
        case 'addNewBillData': {
            const newAppData = addNewBillData(state.data, action.data);
            saveDataState('data', newAppData);
            return {
                ...state,
                data: newAppData,
                timestamp: now,
            };
        }
        case 'loadDataFromFile': {
            saveDataState('data', action.data);
            saveDataState('timestamp', action.timestamp);
            return {
                ...state,
                data: action.data,
                timestamp: action.timestamp,
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
};

const DataStateProvider = (props) => {
    let initialData = props.defaultData;

    if (!loadDataState()) {
        saveDataState(null, initialData);
    } else {
        initialData = loadDataState();
    }

    const [state, dispatch] = React.useReducer(dataStateReducer, initialData);

    return (
        <DataStateContext.Provider value={state}>
            <DataDispatchContext.Provider value={dispatch}>{props.children}</DataDispatchContext.Provider>
        </DataStateContext.Provider>
    );
};

const useDataState = () => {
    const context = React.useContext(DataStateContext);
    if (context === undefined) {
        throw new Error('useDataState must be used within a DataStateProvider');
    }
    return context;
};

const useDataDispatch = () => {
    const context = React.useContext(DataDispatchContext);
    if (context === undefined) {
        throw new Error('useDataDispatch must be used within a DataStateProvider');
    }
    return context;
};

DataStateProvider.propTypes = {
    defaultData: object,
    children: any,
};

export {DataStateProvider, useDataState, useDataDispatch};
