import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'; // eslint-disable-line no-unused-vars
import {Dashboard} from 'modules/Dashboard/Dashboard.jsx';
import {Bills} from 'modules/Bills/Bills.jsx';
import {Charts} from 'modules/Charts/Charts.jsx';
import {Settings} from 'modules/Settings/Settings.jsx';
import {Account} from 'modules/Account/Account.jsx';

export const PageRouter = () => {
    return (
        <Switch>
            <Route path="/bills">
                <Bills />
            </Route>
            <Route path="/charts">
                <Charts />
            </Route>
            <Route path="/settings">
                <Settings />
            </Route>
            <Route path="/account">
                <Account />
            </Route>
            <Route path="/">
                <Dashboard />
            </Route>
        </Switch>
    );
};
