import React from 'react';
import {useAppState} from 'modules/AppState/AppState.jsx';
import {dateFromTimestamp} from 'helpers/dateHelpers';
import {removePayedBills} from 'helpers/dataHelpers';
import {Sum} from 'modules/Global/Sum.jsx';
import {translations} from './translations';
import {object} from 'prop-types';

export const DashboardUnpaid = (props) => {
    const groupName = props.dataGroup.n;
    const dataGroup = [props.dataGroup];
    const {lang} = useAppState();

    console.log(dataGroup);
    const unpaidSum =
        Math.round(
            removePayedBills(dataGroup)
                .map((group) => {
                    return group.r
                        .map((record) => {
                            return record.b.reduce((total, bill) => {
                                return total + bill.q;
                            }, 0);
                        })
                        .reduce((recordTotal, record) => {
                            return recordTotal + record;
                        }, 0);
                })
                .reduce((groupTotal, group) => {
                    return groupTotal + group;
                }, 0) * 100,
        ) / 100;

    return (
        <section className="dashboard__unpaid">
            <h3>
                {groupName} - {translations[lang].unpaidTitle}
            </h3>
            <table className="dashboard__unpaid-table">
                <thead className="dashboard__unpaid-thead">
                    <tr className="dashboard__unpaid-thead-tr">
                        <th className="dashboard__unpaid-thead-th dashboard__unpaid-thead-th--group">{translations[lang].group}</th>
                        <th className="dashboard__unpaid-thead-th dashboard__unpaid-thead-th--record">{translations[lang].record}</th>
                        <th className="dashboard__unpaid-thead-th dashboard__unpaid-thead-th--date">{translations[lang].date}</th>
                        <th className="dashboard__unpaid-thead-th dashboard__unpaid-thead-th--price">{translations[lang].price}</th>
                    </tr>
                </thead>
                <tbody className="dashboard__unpaid-tbody">
                    {removePayedBills(dataGroup).map((group, i) => {
                        return group.r.map((record, j) => {
                            return record.b.map((bill, k) => {
                                return (
                                    <tr key={`b_${i}_${j}_${k}`} className="dashboard__unpaid-tbody-tr" style={{borderColor: record.c, backgroundColor: `${record.c}11`}}>
                                        <td className="dashboard__unpaid-tbody-td dashboard__unpaid-tbody-td--group">{group.n}</td>
                                        <td className="dashboard__unpaid-tbody-td dashboard__unpaid-tbody-td--record">{record.n}</td>
                                        <td className="dashboard__unpaid-tbody-td dashboard__unpaid-tbody-td--date">{dateFromTimestamp(bill.d)}</td>
                                        <td className="dashboard__unpaid-tbody-td dashboard__unpaid-tbody-td--price">{bill.q}</td>
                                    </tr>
                                );
                            });
                        });
                    })}
                    <tr className={`dashboard__unpaid-tbody-tr dashboard__unpaid-tbody-tr--empty ${removePayedBills(dataGroup).length !== 0 ? 'hidden' : ''}`}>
                        <td className="dashboard__unpaid-tbody-td dashboard__unpaid-tbody-td--empty" colSpan="4">
                            {translations[lang].empty}
                        </td>
                    </tr>
                </tbody>
                <tfoot className={`dashboard__unpaid-tfoot ${removePayedBills(dataGroup).length !== 0 ? '' : 'hidden'}`}>
                    <tr className="dashboard__unpaid-tfoot-tr">
                        <td colSpan="3" className="dashboard__unpaid-tfoot-td dashboard__unpaid-tfoot-td--colspan"></td>
                        <td className="dashboard__unpaid-tfoot-td dashboard__unpaid-tfoot-td--sum">
                            <Sum value={unpaidSum} />
                        </td>
                    </tr>
                </tfoot>
            </table>
        </section>
    );
};

DashboardUnpaid.propTypes = {
    dataGroup: object,
};
