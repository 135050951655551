/**
 * Translate query string to an object
 * @param {string} queryString GET string
 * @returns {object|false} object with parameters
 */
export const queryStringToOBJ = (queryString) => {
    let obj = {};
    if (queryString.indexOf('=') > 0) {
        queryString.split('&').forEach((item) => {
            obj[item.split('=')[0]] = item.split('=')[1];
        });
        return obj;
    } else {
        return false;
    }
};

/**
 * Check is a user logged in or not
 * @param {string} url api gateway URL
 * @param {number} userId userId to check
 * @param {string} accessToken accessToken to check for given userId
 * @returns {boolean||object} login status
 */
export const checkLoginStatus = async (url, userId, accessToken) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'text/plain'},
        body: JSON.stringify({userId, accessToken}),
    };
    const response = await fetch(url, requestOptions).then((res) => {
        return (res = res.json());
    });

    return response?.responseData?.data?.loggedin || response?.responseData;
};

/**
 * Refresh all tokens
 * @param {string} url api gateway URL
 * @param {number} userId userId to check
 * @param {string} refreshToken refreshToken used to get new tokens
 * @returns {object} new login data
 */
export const refreshTokens = async (url, userId, refreshToken) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'text/plain'},
        body: JSON.stringify({userId, refreshToken}),
    };
    const response = await fetch(url, requestOptions).then((res) => {
        return (res = res.json());
    });

    return response.responseData;
};

/**
 * Submit login form
 * @param {string} url api gateway URL
 * @param {string} login user login
 * @param {string} passwd user password
 * @returns {object} login data
 */
export const submitLogin = async (url, login, passwd) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'text/plain'},
        body: JSON.stringify({login, passwd}),
    };
    const response = await fetch(url, requestOptions).then((res) => {
        return (res = res.json());
    });

    return response.responseData;
};

/**
 * Submit registration form
 * @param {string} url api gateway URL
 * @param {string} login user login
 * @param {string} email user email
 * @param {string} passwd user password
 * @returns {object} registration data
 */
export const submitRegister = async (url, login, email, passwd) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'text/plain'},
        body: JSON.stringify({login, email, passwd}),
    };
    const response = await fetch(url, requestOptions).then((res) => {
        return (res = res.json());
    });

    return response.responseData;
};

/**
 * Get app data
 * @param {string} url api gateway URL
 * @param {number} userId userId to check
 * @param {string} accessToken accessToken to check for given userId
 * @param {boolean||undefined} timestampOnly flag do you want to get only data timestamp from remote
 * @returns {object} app data
 */
export const getData = async (url, userId, accessToken, timestampOnly) => {
    const getTimestampOnly = timestampOnly === undefined ? false : timestampOnly;
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'text/plain'},
        body: JSON.stringify({userId, accessToken, getTimestampOnly}),
    };
    const response = await fetch(url, requestOptions).then((res) => {
        return (res = res.json());
    });

    return response.responseData;
};

/**
 * Push app data
 * @param {string} url api gateway URL
 * @param {number} userId userId to check
 * @param {string} accessToken accessToken to check for given userId
 * @param {JSON} data JSON data to push
 * @param {number} timestamp data timestamp
 * @returns {JSON} push status
 */
export const pushData = async (url, userId, accessToken, data, timestamp) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'text/plain'},
        body: JSON.stringify({userId, accessToken, data, timestamp}),
    };
    const response = await fetch(url, requestOptions).then((res) => {
        return (res = res.json());
    });

    return response.responseData;
};

/**
 * Activate account
 * @param {string} url api gateway URL
 * @param {number} userId userId to check
 * @param {string} activationToken activationToken used to activate account
 * @returns {object} login data if activated or error msg
 */
export const activateAccount = async (url, userId, activationToken) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'text/plain'},
        body: JSON.stringify({userId, activationToken}),
    };
    const response = await fetch(url, requestOptions).then((res) => {
        return (res = res.json());
    });

    return response.responseData;
};
