import React, {useState, useEffect} from 'react';
import {ChartsGroupChart} from 'modules/Charts/ChartsGroupChart.jsx';
import {useDataState} from 'modules/DataState/DataState.jsx';
import {YearPicker} from 'modules/Global/YearPicker.jsx';

import {getYearsForGroup} from 'helpers/dataHelpers';
import {number} from 'prop-types';

export const ChartsGroup = (props) => {
    const expandedGroup = props.expandedGroup;
    const {data} = useDataState();
    const [groupYear, setGroupYear] = useState(getYearsForGroup(data, expandedGroup)[0]);

    useEffect(() => {
        if (getYearsForGroup(data, expandedGroup).indexOf(groupYear) === -1) {
            setGroupYear(getYearsForGroup(data, expandedGroup)[0]);
        }
    }, [expandedGroup]);

    return (
        <>
            {data.map((group, i) => {
                return (
                    <section key={`gc_${i}`} className={`${i !== expandedGroup ? 'hidden' : ''}`}>
                        <YearPicker expandedGroup={expandedGroup} groupYear={groupYear} setGroupYear={setGroupYear} />
                        <ul key={`gi_${i}`} className="grid__wrapper">
                            {group.r.map((rec, j) => {
                                return (
                                    <li key={`r_${i}_${j}`} className="grid__item grid__item--5050">
                                        <div className="grid__item-inner" style={{borderColor: rec.c, backgroundColor: `${rec.c}11`}}>
                                            <ChartsGroupChart groupIndex={i} recordIndex={j} groupYear={groupYear} />
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </section>
                );
            })}
        </>
    );
};

ChartsGroup.propTypes = {
    expandedGroup: number,
};
