export const translations = {
    PL: {
        all: 'Wszystkie',
        sum: 'Suma',
    },
    ENG: {
        all: 'All',
        sum: 'Sum',
    },
};
