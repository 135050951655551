import React from 'react';
import {useDataState} from 'modules/DataState/DataState.jsx';
import {SettingsGroupRemove} from './SettingsGroupRemove.jsx';
import {SettingsGroupRecord} from './SettingsGroupRecord.jsx';
import {SettingsGroupRecordNew} from './SettingsGroupRecordNew.jsx';
import {func, number} from 'prop-types';

export const SettingsGroup = (props) => {
    const {data} = useDataState();

    return (
        <>
            {data.map((group, i) => {
                return (
                    <section key={`gc_${i}`} className={`${i !== props.expandedGroup ? 'hidden' : ''}`}>
                        <SettingsGroupRemove setExpandedGroup={props.setExpandedGroup} groupName={group.n} groupIndex={i} />
                        <ul key={`gi_${i}`} className="grid__wrapper">
                            {group.r.map((rec, j) => {
                                return <SettingsGroupRecord key={`r_${i}_${j}`} groupName={group.n} groupIndex={i} record={rec} recordIndex={j} />;
                            })}
                            <SettingsGroupRecordNew groupName={group.n} groupIndex={i} />
                        </ul>
                        <SettingsGroupRemove setExpandedGroup={props.setExpandedGroup} groupName={group.n} groupIndex={i} />
                    </section>
                );
            })}
        </>
    );
};

SettingsGroup.propTypes = {
    setExpandedGroup: func,
    expandedGroup: number,
};
