import React from 'react';
import {useAppState} from 'modules/AppState/AppState.jsx';
import {useDataState, useDataDispatch} from 'modules/DataState/DataState.jsx';
import {getSaveFileName} from 'helpers/dateHelpers';
import {RiSave3Fill} from 'react-icons/ri';
import {FaFileUpload} from 'react-icons/fa';
import {translations} from './translations';

export const HeaderOptions = () => {
    const {lang} = useAppState();
    const {data, timestamp} = useDataState();
    const dataDispatch = useDataDispatch();

    const loadDataFromFile = (jsonData) => {
        dataDispatch({type: 'loadDataFromFile', data: jsonData.data, timestamp: jsonData.timestamp});
    };

    return (
        <div className="header__options">
            <button
                className="header__options-btn header__options-btn--save"
                title={translations[lang].save}
                onClick={() => {
                    const saveOBJ = JSON.stringify({data: data, timestamp: timestamp});
                    const fileName = getSaveFileName();
                    const blob = new Blob([saveOBJ], {type: 'application/json'});
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.download = fileName;
                    link.href = url;
                    link.click();
                }}
            >
                <RiSave3Fill className="header__options-icon" />
                <span className="header__nav-list-text header__options-label">{translations[lang].save}</span>
            </button>

            <label htmlFor="loadFile" className="header__options-btn header__options-btn--load" title={translations[lang].load}>
                <FaFileUpload className="header__options-icon" />
                <span className="header__nav-list-text header__options-label">{translations[lang].load}</span>
            </label>
            <input
                id="loadFile"
                type="file"
                className="header__options-input--load"
                onChange={(e) => {
                    let file = e.target.files[0];
                    if (file) {
                        var reader = new FileReader();
                        reader.readAsText(file, 'UTF-8');
                        reader.onload = function (evt) {
                            try {
                                let jsonData = JSON.parse(evt.target.result);
                                loadDataFromFile(jsonData);
                            } catch (err) {
                                throw new Error(`Error reading file: ${err}`);
                            }
                        };
                        reader.onerror = function (err) {
                            throw new Error(`Error reading file: ${err}`);
                        };
                    }
                }}
            />
        </div>
    );
};
