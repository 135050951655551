import React from 'react';
import {useAppState} from 'modules/AppState/AppState.jsx';
import {translations} from './translations';

export const AccountEdit = () => {
    const {lang} = useAppState();

    return (
        <div className="account__login">
            <h3 className="appSection__heading">{translations[lang].editHeader}</h3>
            <div className="account__login-wrapper"></div>
        </div>
    );
};
