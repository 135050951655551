import React, {useState, useEffect} from 'react';
import {queryStringToOBJ, activateAccount} from 'helpers/authHelpers';
import {useAccountDispatch} from 'modules/AccountState/AccountState.jsx';
import {useAppState} from 'modules/AppState/AppState.jsx';
import {VscLoading} from 'react-icons/vsc';
import {translations} from './translations';

export const AccountActivate = () => {
    const {lang} = useAppState();
    const accountDispatch = useAccountDispatch();

    const [activating, setActivating] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [activationData, setActivationData] = useState();

    const queryString = window.location.search.replace('?', '');

    const handleErrorMsg = (data) => {
        if (data === null) {
            setErrorMsg(translations[lang].errorFetch);
        } else if (data?.userFound === false) {
            setErrorMsg(translations[lang].errorActivationUserNotFound);
        } else if (data?.tokenExpired === true) {
            setErrorMsg(translations[lang].errorActivationToken);
        } else {
            setErrorMsg('');
        }
    };

    useEffect(() => {
        handleErrorMsg(activationData);
    }, [lang]);

    useEffect(() => {
        const params = queryStringToOBJ(queryString);
        if (params?.t && params?.i) {
            setActivating(true);
            activateAccount(`${process.env.NETLIFY_FUNCTIONS_URL}${process.env.NETLIFY_FUNCTION_ACTIVATEACCOUNT_URL}`, params.i, params.t).then((response) => {
                if (response.error === false) {
                    const tokenData = response.data;
                    accountDispatch({type: 'setLoggedIn', data: true});
                    accountDispatch({type: 'setTokens', data: tokenData});
                    setTimeout(() => {
                        window.location.search = '';
                    }, 0);
                } else {
                    setActivationData(response.data);
                    handleErrorMsg(response.data);
                }
                setActivating(false);
            });
        } else if (queryString) {
            window.location.search = '';
        }
    }, [queryString]);

    return (
        <div className="account__activation">
            <h3 className="appSection__heading">{translations[lang].activateHeader}</h3>
            <div className="account__activation-wrapper">
                {activating ? <VscLoading className="spin" /> : ''}
                {errorMsg ? (
                    <>
                        <p className="account__activation-error">{errorMsg}</p>
                        <button
                            className="global__groups-button"
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.search = '';
                            }}
                        >
                            {translations[lang].pageTitle}
                        </button>
                    </>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};
