import {getFromStorage, setToStorage} from 'helpers/localStorageHelpers';

/**
 * Load dataState data from local storage
 * @param {string} key optional: key value
 * @returns {object} appState data from local storage
 */
export const loadDataState = (key) => {
    return getFromStorage('billableDataState', key);
};

/**
 * Save dataState data to local storage
 * @param {string} key optional: key value to set
 * @param {any} value value to set - if not provided will set to entire dataState
 * @returns {object} entire dataState data object
 */
export const saveDataState = (key, value) => {
    return setToStorage('billableDataState', key, value);
};

/**
 * Add new blank group to appData
 * @param {array} data current appData.data
 * @param {string} newGroupName new group name
 * @returns {array} appData.data with new group
 */
export const addNewGroup = (data, newGroupName) => {
    const newGroup = {
        n: newGroupName,
        r: [],
    };
    data.push(newGroup);

    return data;
};

/**
 * Remove existing group by name and index
 * @param {array} data current appData.data
 * @param {object} removeGroupData data on which group to remove
 * @returns {array} appData.data without given group
 */
export const removeGroup = (data, removeGroupData) => {
    if (data[removeGroupData.index].n === removeGroupData.name) {
        data.splice(removeGroupData.index, 1);
    } else {
        throw new Error(`Error in removeGroup(). Group index does not match group name. Expected ${removeGroupData.name} got ${data[removeGroupData.index].n}`);
    }

    return data;
};

/**
 * Rename existing group by index
 * @param {array} data current appData.data
 * @param {object} renameGroupData data on which group to rename
 * @returns {array} appData.data with renamed group
 */
export const renameGroup = (data, renameGroupData) => {
    data[renameGroupData.groupIndex].n = renameGroupData.newName;
    return data;
};

/**
 * Update record by given group index and record index
 * @param {array} data current appData.data
 * @param {object} recordData record data
 * @returns {array} updated appData.data
 */
export const updateRecord = (data, recordData) => {
    data[recordData.groupIndex].r[recordData.recordIndex][recordData.key] = recordData.newValue;

    return data;
};

/**
 * Remove record from a given group
 * @param {array} data current appData.data
 * @param {object} removeGroupRecordData record data
 * @returns {array} updated appData.data
 */
export const removeGroupRecord = (data, removeGroupRecordData) => {
    if (data[removeGroupRecordData.groupIndex].n === removeGroupRecordData.groupName) {
        if (data[removeGroupRecordData.groupIndex].r[removeGroupRecordData.recordIndex].n === removeGroupRecordData.recordName) {
            data[removeGroupRecordData.groupIndex].r.splice(removeGroupRecordData.recordIndex, 1);
        } else {
            throw new Error(
                `Error in removeGroupRecord(). Record index does not match record name. Expected ${removeGroupRecordData.recordName} got ${
                    data[removeGroupRecordData.groupIndex].r[removeGroupRecordData.recordIndex].n
                }`,
            );
        }
    } else {
        throw new Error(
            `Error in removeGroupRecord(). Group index does not match group name. Expected ${removeGroupRecordData.groupName} got ${data[removeGroupRecordData.groupIndex].n}`,
        );
    }

    return data;
};

/**
 * Add record to a given group
 * @param {array} data current appData.data
 * @param {object} addGroupRecordData record data
 * @returns {array} updated appData.data
 */
export const addGroupRecord = (data, addGroupRecordData) => {
    if (data[addGroupRecordData.groupIndex].n === addGroupRecordData.groupName) {
        data[addGroupRecordData.groupIndex].r.push(addGroupRecordData.data);
    } else {
        throw new Error(`Error in removeGroup(). Group index does not match group name. Expected ${addGroupRecordData.name} got ${data[addGroupRecordData.groupIndex].n}`);
    }

    return data;
};

/**
 * Update bill data
 * @param {array} data current appData.data
 * @param {object} editBillData details on bill to update
 * @returns {array} updated appData.data
 */
export const updateBillData = (data, editBillData) => {
    // console.log(data);
    // console.log(editBillData);

    const bills = data[editBillData.groupIndex].r[editBillData.recordIndex].b;
    const bill = bills[editBillData.billIndex];

    switch (editBillData.key) {
        case 'd':
            bill.d = editBillData.value.getTime();
            bill.y = editBillData.value.getFullYear();
            // FIXME: Sorting is done in the component now - should be done here as well probably but
            // "reversed" order makes it more complex
            // bills.sort((a, b) => {
            //     if (a.d < b.d) return -1;
            //     if (a.d > b.d) return 1;
            //     return 0;
            // });
            break;
        case 'q':
            bill.q = Math.round(parseFloat(editBillData.value) * 100) / 100;
            break;
        case 'p':
            bill.p = editBillData.value;
            break;
        case 'c':
            bill.c = editBillData.value;
            break;
        default:
            throw new Error(`Key "${editBillData.key}" not handled in updateBillData()`);
    }

    return data;
};

/**
 * Remove specific bill
 * @param {array} data current appData.data
 * @param {object} editBillData details on bill to remove
 * @returns {array} updated appData.data
 */
export const removeBillData = (data, editBillData) => {
    // console.log(data);
    // console.log(editBillData);

    const bills = data[editBillData.groupIndex].r[editBillData.recordIndex].b;
    bills.splice(editBillData.billIndex, 1);

    return data;
};

/**
 * Add new bill
 * @param {array} data current appData.data
 * @param {object} editBillData details on bill to added
 * @returns {array} updated appData.data
 */
export const addNewBillData = (data, editBillData) => {
    // console.log(data);
    // console.log(editBillData);

    const bills = data[editBillData.groupIndex].r[editBillData.recordIndex].b;
    bills.push(editBillData.newBillData);
    bills.sort((a, b) => {
        if (a.d < b.d) return -1;
        if (a.d > b.d) return 1;
        return 0;
    });

    return data;
};
