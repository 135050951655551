import React, {useState} from 'react';
import {useAppState} from 'modules/AppState/AppState.jsx';
import {useDataState, useDataDispatch} from 'modules/DataState/DataState.jsx';
import {GoPlus} from 'react-icons/go';
import {func, number} from 'prop-types';
import {translations} from './translations';

export const SettingsGroupPicker = (props) => {
    const {lang} = useAppState();
    const {data} = useDataState();
    const dataDispatch = useDataDispatch();
    const [newGroupName, setNewGroupName] = useState('');

    const addNewGroup = () => {
        if (newGroupName) {
            dataDispatch({type: 'addGroup', data: newGroupName});
            setNewGroupName('');
            if (data.length) {
                props.setExpandedGroup(data.length - 1);
            }
        }
    };

    const renameGroup = (groupIndex, newName) => {
        if (newName.length > 0) {
            dataDispatch({type: 'renameGroup', data: {groupIndex: groupIndex, newName: newName}});
        }
    };

    return (
        <ul className="settings__groups-list">
            {data.map((group, i) => {
                return (
                    <li key={`g_${i}`} className="settings__groups-item">
                        <label>
                            <span className="sr-only">{translations[lang].groupName}</span>
                            <input
                                className={`settings__groups-input ${i == props.expandedGroup ? 'settings__groups-input--current' : ''}`}
                                type="text"
                                value={group.n}
                                onClick={() => {
                                    props.setExpandedGroup(i);
                                }}
                                onChange={(e) => {
                                    renameGroup(i, e.target.value);
                                }}
                                style={{width: `${group.n.length * 9 + 24}px`}}
                            />
                        </label>
                    </li>
                );
            })}
            <li className="settings__groups-item">
                <label>
                    <span className="sr-only">{translations[lang].newGroup}</span>
                    <input
                        type="text"
                        value={newGroupName}
                        className="settings__groups-new"
                        onChange={(e) => {
                            setNewGroupName(e.target.value);
                        }}
                    />
                </label>
                <button
                    title={translations[lang].addText}
                    className="settings__groups-new-btn"
                    onClick={() => {
                        addNewGroup();
                    }}
                >
                    <GoPlus className="settings__groups-new-icon" />
                </button>
            </li>
        </ul>
    );
};

SettingsGroupPicker.propTypes = {
    setExpandedGroup: func,
    expandedGroup: number,
};
