// Imports
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import {App} from './app.jsx';

const app_wrapper = document.querySelector('#app-wrapper');

// Don't run on a non-React page
if (app_wrapper !== null) {
    ReactDOM.render(
        <>
            <App />
        </>,
        app_wrapper,
    );
}
