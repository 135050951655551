import React, {useState, useEffect} from 'react';
import {submitLogin} from 'helpers/authHelpers';
import {useAccountState, useAccountDispatch} from 'modules/AccountState/AccountState.jsx';
import {useAppState} from 'modules/AppState/AppState.jsx';
import {VscLoading} from 'react-icons/vsc';
import {RiMapPinUserLine, RiLockPasswordLine} from 'react-icons/ri';
import {translations} from './translations';

const accountStateDefaultData = require('modules/AccountState/accountStateDefaultData.json');

export const AccountLogin = () => {
    const {lang} = useAppState();
    const accountDispatch = useAccountDispatch();
    const {loading, loggedIn} = useAccountState();

    const [loginInProgress, setLoginInProgress] = useState(false);
    const [login, setLogin] = useState('');
    const [passwd, setPasswd] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [loginData, setLoginData] = useState({error: false, data: {}});

    useEffect(() => {
        handleErrorMsg(loginData);
    }, [lang, loggedIn]);

    const submitLoginForm = (e) => {
        e.preventDefault();
        if (!loginInProgress && login.length > 0 && passwd.length > 0) {
            setLoginInProgress(true);
            accountDispatch({type: 'setLoading', data: true});
            submitLogin(`${process.env.NETLIFY_FUNCTIONS_URL}${process.env.NETLIFY_FUNCTION_LOGIN_URL}`, login, passwd).then((response) => {
                if (response.error === false) {
                    const tokenData = response.data;
                    setErrorMsg('');
                    setLoginData(tokenData);
                    accountDispatch({type: 'setLoggedIn', data: true});
                    accountDispatch({type: 'setTokens', data: tokenData});
                } else {
                    handleErrorMsg(response.data);
                    setLoginData(response.data);
                    accountDispatch({type: 'setLoggedIn', data: false});
                }
                accountDispatch({type: 'setLoading', data: false});
                setLoginInProgress(false);
            });
        }
    };

    const handleErrorMsg = (data) => {
        if (data === null) {
            setErrorMsg(translations[lang].errorFetch);
        } else if (data.userId === false) {
            setErrorMsg(translations[lang].errorUserId);
        } else if (data.password === false) {
            setErrorMsg(translations[lang].errorPassword);
        } else {
            setErrorMsg('');
        }
    };

    const submitLogout = () => {
        accountDispatch({type: 'setTokens', data: accountStateDefaultData});
    };

    return (
        <div className="account__login">
            <h3 className="appSection__heading">{translations[lang].loginHeader}</h3>
            <div className="account__login-wrapper">
                {loading || undefined === loading ? (
                    <VscLoading className="spin" />
                ) : loggedIn && !loading ? (
                    <button
                        className="global__groups-button account__login-logout"
                        onClick={() => {
                            submitLogout();
                        }}
                    >
                        {translations[lang].logoutSubmitText}
                    </button>
                ) : !loggedIn && !loading ? (
                    <form
                        className="account__login-form"
                        onSubmit={(e) => {
                            submitLoginForm(e);
                        }}
                    >
                        <p className="account__login-error">{errorMsg}</p>
                        <label htmlFor="loginInput" className="account__login-label">
                            <span className="account__login-text">{translations[lang].loginLabel}</span>
                            <RiMapPinUserLine className="account__login-icon" aria-hidden="true" />
                            <input
                                id="loginInput"
                                type="text"
                                value={login}
                                className="account__login-field"
                                onChange={(e) => {
                                    setLogin(e.target.value);
                                }}
                            />
                        </label>
                        <label htmlFor="passwordInput" className="account__login-label">
                            <span className="account__login-text">{translations[lang].passwdLabel}</span>
                            <RiLockPasswordLine className="account__login-icon" aria-hidden="true" />
                            <input
                                id="passwordInput"
                                type="password"
                                value={passwd}
                                className="account__login-field"
                                onChange={(e) => {
                                    setPasswd(e.target.value);
                                }}
                            />
                        </label>
                        <button type="submit" className="global__groups-button account__login-submit">
                            {translations[lang].loginSubmitText}
                        </button>
                    </form>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};
