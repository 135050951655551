import React, {useState} from 'react';
import {useAppState, useAppDispatch} from 'modules/AppState/AppState.jsx';
import {useAccountState} from 'modules/AccountState/AccountState.jsx';
import {HeaderOptions} from 'modules/Header/HeaderOptions.jsx';
import {AccountIcon} from 'modules/Account/AccountIcon.jsx';
import {AccountIconOptions} from 'modules/Account/AccountIconOptions.jsx';
import {HeaderBottom} from 'modules/Header/HeaderBottom.jsx';
import {BrowserRouter as Router, NavLink} from 'react-router-dom'; // eslint-disable-line no-unused-vars
import {translations} from './translations';
import {GoArrowRight, GoDashboard, GoCalendar, GoGraph, GoTools} from 'react-icons/go';
import {GiTakeMyMoney} from 'react-icons/gi';

export const Header = () => {
    const {lang, menuExpanded} = useAppState();
    const {loggedIn} = useAccountState();
    const appDispatch = useAppDispatch();
    const [menuIsExpanded, setMenuIsExpanded] = useState(menuExpanded);
    const toggleMenu = () => {
        appDispatch({type: 'toggleMenu', data: !menuIsExpanded});
        setMenuIsExpanded(!menuIsExpanded);
    };

    return (
        <header className={menuIsExpanded ? 'header header--expanded' : 'header header--collapsed'}>
            <div className="header__top">
                <button className="header__toggle" title={translations[lang].toggleMenuSize} onClick={() => toggleMenu()}>
                    <GoArrowRight className="header__toggle-icon" />
                </button>

                <NavLink exact to="/" className="header__logo-link" title={translations[lang].homepage} activeClassName="header__logo-link--selected">
                    <GiTakeMyMoney className="header__logo-icon" />
                    <h1 className="header__logo-heading">{translations[lang].appName}</h1>
                </NavLink>

                <nav>
                    <ul className="header__nav-list">
                        <li className="header__nav-list-item">
                            <NavLink exact to="/" className="header__nav-list-link" title={translations[lang].linkDashboard} activeClassName="header__nav-list-link--selected">
                                <GoDashboard className="header__nav-list-icon" />
                                <span className="header__nav-list-text">{translations[lang].linkDashboard}</span>
                            </NavLink>
                        </li>
                        <li className="header__nav-list-item">
                            <NavLink exact to="/bills" className="header__nav-list-link" title={translations[lang].linkBills} activeClassName="header__nav-list-link--selected">
                                <GoCalendar className="header__nav-list-icon" />
                                <span className="header__nav-list-text">{translations[lang].linkBills}</span>
                            </NavLink>
                        </li>
                        <li className="header__nav-list-item">
                            <NavLink exact to="/charts" className="header__nav-list-link" title={translations[lang].linkCharts} activeClassName="header__nav-list-link--selected">
                                <GoGraph className="header__nav-list-icon" />
                                <span className="header__nav-list-text">{translations[lang].linkCharts}</span>
                            </NavLink>
                        </li>
                        <li className="header__nav-list-item">
                            <NavLink
                                exact
                                to="/settings"
                                className="header__nav-list-link"
                                title={translations[lang].linkSettings}
                                activeClassName="header__nav-list-link--selected"
                            >
                                <GoTools className="header__nav-list-icon" />
                                <span className="header__nav-list-text">{translations[lang].linkSettings}</span>
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink exact to="/account" className="header__nav-list-link" title={translations[lang].linkAccount} activeClassName="header__nav-list-link--selected">
                                <AccountIcon />
                            </NavLink>
                            {loggedIn ? <AccountIconOptions /> : ''}
                        </li> */}
                    </ul>
                </nav>
                <HeaderOptions />
            </div>
            <HeaderBottom />
        </header>
    );
};
