import React from 'react';
import {useAppState} from 'modules/AppState/AppState.jsx';
import {useDataState} from 'modules/DataState/DataState.jsx';
import {getYearsForGroup} from 'helpers/dataHelpers';
import {translations} from './translations';
import {number, func} from 'prop-types';

export const YearPicker = (props) => {
    const groupIndex = props.expandedGroup;
    const groupYear = props.groupYear;
    const setGroupYear = props.setGroupYear;
    const {lang} = useAppState();
    const {data} = useDataState();

    return (
        <ul className="bills__group-years-list">
            {getYearsForGroup(data, groupIndex).map((year, i) => {
                return (
                    <li key={`year_${groupIndex}_${i}`} className="bills__group-years-item">
                        <button
                            className={`global__groups-button ${groupYear === year ? 'global__groups-button--current' : ''}`}
                            onClick={() => {
                                setGroupYear(year);
                            }}
                        >
                            {`${year === -1 ? translations[lang].all : year}`}
                        </button>
                    </li>
                );
            })}
        </ul>
    );
};

YearPicker.propTypes = {
    expandedGroup: number,
    groupYear: number,
    setGroupYear: func,
};
